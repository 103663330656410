.locations-container {
  width: 100%;
  padding: 10px;
}
.autocomplete-dropdown-container {
  width: 100%;
  padding: 10px;
  background-color: white;
  margin-top: 10px;
  border-radius: 4px;
  color: #311e06;
}
.suggestion {
  padding: 10px;
}
.suggestion span {
  display: block;
  font-size: 1em;
}
.suggestion span:first-child {
  font-size: 1.3em;
  font-weight: 600;
}
.categories-list {
  margin-top: 30px;
}
.category-item {
  width: 120px;
  height: 120px;
  border-radius: 45%;
  background-color: #f7941d;
  margin: 10px;
  cursor: pointer;
}

.category-menu span {
  font-size: 1.3em;
  text-align: center;
}
.category-menu {
  margin: 10px;
  max-width: 120px;
}
.category-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}
.search-container {
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background: #311e06;
  background: radial-gradient(
    circle at bottom left,
    #5b2501,
    #311e06,
    #311e06,
    #5b2501
  );
  padding: 10px;
}
.btn-cancel {
  display: block;
  margin: 10px;
  height: 40px;
  margin-left: auto !important;
  background-color: none;
  background: none;
  color: #f7941d;
  font-family: "SharpSmBld25";
  text-decoration: underline;
  cursor: pointer;
}
button {
  cursor: pointer;
}
.btn {
  background: #311e06;
  background: radial-gradient(
    circle at bottom left,
    #5b2501,
    #311e06,
    #311e06,
    #5b2501
  );
  color: white;
  border-radius: 4px;
}
.cinfo input {
  width: 100%;
  border: 2px solid #f7941d;
  margin-bottom: 10px;
  font-size: 0.9em;
  height: 40px;
  color: #311e06;
}
.inputs input {
  width: 49%;
}
.order-tracking h2 {
  color: #f7941d;
}
.order-tracking .pricing-table td {
  /* font-size: 1.5em !important; */
  width: 20%;
  padding: 10px;
}
.order-tracking .pricing-table tr td:nth-child(2) {
  text-align: left;
  width: 80% !important;
  font-family: "SharpMedium25";
}
.pricing-table tr td:nth-child(2) {
  text-align: left;
}
.table50 tr td {
  width: 50%;
}
.order-tracking .pricing-table td a {
  text-decoration: underline;
  padding: 10px;
  color: #f7941d;
  padding-left: 0px;
}
.s-status {
  font-size: 9px !important;
  font-weight: 700;
  padding: 5px;
  border-radius: 4px;
  width: fit-content;
  color: white;
}
.s-status.active,
.s-status.true,
.s-status.available,
.s-status.success {
  background-color: var(--alerts-success) !important;
}
.processing,
.completed {
  background-color: var(--alerts-info);
}
.delivered {
  background-color: var(--main-gray-black);
}
.s-status.inactive,
.s-status.false,
.s-status.sold,
.s-status.blocked,
.s-status.canceled {
  background-color: var(--alerts-danger) !important;
}
.s-status.placed,
.s-status.pending {
  background-color: var(--alerts-warn) !important;
}
#map {
  width: 100%;
  height: 500px;
  border-radius: 10px;
}
.check {
  /* width: 30px;
  height: 30px; */
  margin-right: 5px;
  cursor: pointer;
}
.acct span {
  display: block;
  margin-bottom: 5px;
}
.track-btn {
  margin-top: 30px;
  background-color: var(--yellow-main);
  color: white;
  font-family: "SpaceSemiBold";
  border-radius: 10px;
  font-size: 2em;
  padding: 15px;
  height: unset;
  margin-bottom: 15px;
}
.sold-out {
  background-color: var(--alerts-danger);
  color: white;
  font-weight: 800;
  padding: 2px 4px;
  position: absolute;
  right: 0;
  z-index: 10;
}
.menu-item {
  position: relative;
}
button:disabled {
  opacity: 0.3 !important;
}
