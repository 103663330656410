.home_banner {
  width: 100%;
  /* height: calc(100vh - 10.4rem); */
  padding: 1.6rem;
  /* padding-bottom: 1.6rem; */
  background: #311e06;
  background: radial-gradient(
    circle at bottom left,
    #5b2501,
    #311e06,
    #311e06,
    #5b2501
  );
  overflow: hidden;
}
.home_banner section {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6.1rem;
}

.home_banner section .home_banner-left,
.home_banner section .home_banner-right {
  flex: 1 1 20rem;
  padding-top: 2.5rem;
}

.home_banner section .home_banner-left h1 {
  max-width: 50rem;
  width: 100%;
  color: #ffffff;
}

.home_banner section .home_banner-left p {
  font-size: 1.8rem !important;
  max-width: 38rem;
  width: 100%;
  color: #ffffff;
}

.home_banner section .home_banner-left .home_banner-btns {
  margin-top: 3.2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
  height: 5.6rem;
}

.home_banner section .home_banner-left .home_banner-btns .banner_btn {
  display: grid;
  place-items: center;
  height: 100%;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 0 2.4rem;
  font-size: 1.4rem;
  font-family: "SpaceMedium";
  color: #000000;
  cursor: pointer;
  transition: box-shadow 0.15s ease;
}

.home_banner section .home_banner-left .home_banner-btns .banner_btn:hover {
  box-shadow: -5px 5px 0px 0px #ffffff;
  -webkit-box-shadow: -5px 5px 0px 0px #ffffff;
  -moz-box-shadow: -5px 5px 0px 0px #ffffff;
}

.home_banner
  section
  .home_banner-left
  .home_banner-btns
  .banner_btn:first-child {
  background-color: #f7941d;
}

.home_banner
  section
  .home_banner-left
  .home_banner-btns
  .banner_btn:last-child {
  background-color: #ffffff;
}

.home_banner section .home_banner-right {
  height: 100%;
  width: 100%;
  position: relative;
}

.home_banner section .home_banner-right .banner_star {
  position: absolute;
  animation: scaling 2s ease-out infinite;
}

.home_banner section .home_banner-right .banner_star.one {
  top: 7rem;
  left: -2rem;
}

.home_banner section .home_banner-right .banner_star.two {
  top: 9rem;
  right: 3rem;
  animation-delay: 5s;
}

.home_banner section .home_banner-right .banner_star.three {
  bottom: 13rem;
  left: 3rem;
}

.home_banner section .home_banner-right .banner_star.four {
  bottom: 3rem;
  left: 13rem;
  animation-delay: 5s;
}

@keyframes scaling {
  0%,
  100% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(0.7);
  }
}

@media screen and (max-width: 768px) {
  .home_banner section {
    flex-direction: column-reverse;
  }

  .home_banner section .home_banner-left,
  .home_banner section .home_banner-right {
    flex: 1;
  }

  .home_banner section .home_banner-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .home_banner section .home_banner-right {
    width: 37rem;
    height: 37rem;
  }
}

@media screen and (max-width: 532px) {
  .home_banner section {
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .home_banner section .home_banner-left,
  .home_banner section .home_banner-right {
    flex: 1;
  }

  .home_banner section .home_banner-left {
    align-items: flex-start;
    text-align: left;
  }

  .home_banner section .home_banner-left h1,
  .home_banner section .home_banner-left p {
    max-width: 100%;
  }

  .home_banner section .home_banner-right {
    width: 37rem;
    height: 37rem;
  }
}
.about_container {
  padding: 5rem 1.6rem 0;
  margin-bottom: 4rem;
}

.about_container section {
  margin-bottom: 4rem;
}

.about_container section .about_card-1 {
  margin-bottom: 4rem;
  background-color: #fef7e0;
  width: 100%;
  padding: 3.2rem 2.4rem;
  border-radius: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 9.6rem;
}

.about_container section .about_card-1 .card-1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 4rem;
}

.about_container section .about_card-1 .card-1 .card-1_top {
  max-width: 38.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #666666;
}

.about_container section .about_card-1 .card-1 .card-1_top p {
  margin-bottom: 0.8rem;
}

.about_container section .about_card-1 .card-1 .about_card-img {
  max-width: 72rem;
  width: 100%;
  aspect-ratio: 2;
  background-color: #ececec;
  border-radius: 2.4rem;
  overflow: hidden;
}

.about_container section .about_card-1 .card-2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
  gap: 7.5rem;
}

.about_container section .about_card-1 .card-2 h3 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  margin: 3.2rem 0 1.6rem;
}

.about_container section .about_card-1 .card-2 h3 span {
  color: #f7941d;
}

.about_container section .about_card-1 .card-2 p {
  text-align: justify;
  line-height: 2.25rem;
}

.about_container section .about_card-1 .card-2 .card_2-icon {
  width: 5.6rem;
  max-height: 5.6rem;
  border-radius: 1.6rem;
  background-color: #ffecb3;
  padding: 0.5rem;
}

@media screen and (max-width: 768px) {
  .about_container section .about_card-1 {
    padding: 4rem 1.6rem;
    gap: 5.6rem;
  }

  .about_container section .about_card-1 .card-1 {
    flex-wrap: wrap;
  }

  .about_container section .about_card-1 .card-1 .card-1_top,
  .about_container section .about_card-1 .card-1 .about_card-img {
    max-width: 100%;
  }

  .about_container section .about_card-1 .card-1 .about_card-img {
    aspect-ratio: 1.7;
  }

  .about_container section .about_card-1 .card-2 {
    gap: 5.6rem;
  }
}

.about_container section .about_card-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 6.4rem;
}

.about_container section .about_card-2 .what_we_do {
  max-width: 68.7rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 7.5rem;
}

.about_container section .about_card-2 .what_we_do .what_we_do-card {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  color: #666666;
  width: 100%;
}

.about_container
  section
  .about_card-2
  .what_we_do
  .what_we_do-card
  .what_we_do-card_top {
  display: flex;
  align-items: flex-end;
}

.about_container
  section
  .about_card-2
  .what_we_do
  .what_we_do-card
  .what_we_do-card_top
  div {
  max-width: 7.8rem;
  height: 7.8rem;
  margin-right: 2rem;
  border-radius: 16px;
  background-color: #ffecb3;
  padding: 1rem;
}

.about_container
  section
  .about_card-2
  .what_we_do
  .what_we_do-card
  .what_we_do-card_top
  h3 {
  font-weight: 500;
  max-width: 20.8rem;
  width: 100%;
}

.about_container
  section
  .about_card-2
  .what_we_do
  .what_we_do-card
  .what_we_do-card_top
  p {
  font-family: "RegularFontFamily";
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .about_container section .about_card-2 .what_we_do {
    max-width: 100%;
    gap: 5rem;
  }
}

.contact_container {
  padding: 0 var(--px);
  margin-bottom: var(--mb);
}
.contact_container section .contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--black-tint-3);
  margin-bottom: var(--mb);
}
.contact_container section .contact .contact_cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28.2rem, 1fr));
  grid-auto-rows: 28.2rem;
  grid-column-gap: 8.4rem;
  grid-row-gap: 3.2rem;
}
.contact_container section .contact .contact_cards .contact_card-content {
  background-color: var(--yellow-light);
  border-radius: 2.4rem;
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--black-tint-3);
}
.contact_container
  section
  .contact
  .contact_cards
  .contact_card-content
  .contact_card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 1.6rem;
  background-color: var(--yellow-tint-1);
}
.contact_container section .contact .contact_cards .contact_card-content h4 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 3.2rem 0 1.6rem;
}
.contact_container
  section
  .contact
  .contact_cards
  .contact_card-content
  h4
  span {
  color: var(--yellow-main);
}
.contact_container section .contact .contact_cards .contact_card-content p {
  font-weight: 400;
}
.contact_container section .contact_form {
  background-color: var(--yellow-tint-6);
  border-radius: 2.4rem;
  padding: 6.4rem 1.5rem;
  color: var(--white-main);
}
.contact_container section .contact_form .form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 58.8rem;
  margin: 0 auto;
  position: relative;
}
.contact_container section .contact_form .form .contact_star {
  position: absolute;
  animation: scaling 2s ease-out infinite;
}
.contact_container section .contact_form .form .contact_star.one {
  top: -1rem;
  left: -8rem;
  animation-delay: 3s;
}
.contact_container section .contact_form .form .contact_star.two {
  top: -1rem;
  right: -8rem;
}
.contact_container section .contact_form .form .contact_star.three {
  bottom: -3rem;
  left: -15rem;
  animation-delay: 3s;
}
.contact_container section .contact_form .form .contact_star.four {
  bottom: 3rem;
  right: -15rem;
}
@keyframes scaling {
  0%,
  100% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(0.7);
  }
}
.contact_container section .contact_form .form form {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  max-width: 58.8rem;
  width: 100%;
}
.contact_container section .contact_form .form form .inputs_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28.2rem, 1fr));
  grid-gap: 2.4rem;
}
.contact_container section .contact_form .form form .form_control {
  display: flex;
  flex-direction: column;
}
.contact_container section .contact_form .form form .form_control p {
  margin-bottom: 0.8rem;
  font-family: var(--medium-500);
}
.contact_container section .contact_form .form form .form_control input,
.contact_container section .contact_form .form form .form_control textarea {
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--white-main);
  border-radius: 8px;
  padding: 2.4rem;
  color: inherit;
  font-family: var(--medium-500);
  font-size: 1.4rem;
}
.contact_container
  section
  .contact_form
  .form
  form
  .form_control
  input::placeholder,
.contact_container
  section
  .contact_form
  .form
  form
  .form_control
  textarea::placeholder {
  color: var(--black-tint-2);
}
.contact_container section .contact_form .form form .form_control input {
  height: 5.6rem;
}
.contact_container section .contact_form .form form .form_control textarea {
  height: 15.2rem;
}
.contact_container
  section
  .contact_form
  .form
  form
  .form_control
  textarea::-webkit-scrollbar {
  display: none;
}
.contact_container section .contact_form .form form .form_btn {
  background-color: var(--yellow-main);
  font-size: 1.4rem;
  color: var(--black-main);
  font-family: var(--medium-500);
  border-radius: 8px;
  transition: box-shadow 0.15s ease;
  cursor: var(--pointer);
}
.contact_container section .contact_form .form form .form_btn:hover {
  box-shadow: -5px 5px 0px 0px var(--black-main);
  -webkit-box-shadow: -5px 5px 0px 0px var(--black-main);
  -moz-box-shadow: -5px 5px 0px 0px var(--black-main);
}
@media screen and (max-width: 768px) {
  .contact_container section .contact_form .form {
    padding: 4rem 1.5rem;
  }
}
