@font-face {
  font-family: "SharpMedium25";
  src: url("../assets/fonts/Sharp_Grotesk/SharpGroteskMedium25.ttf");
}

@font-face {
  font-family: "SharpSmBld25";
  src: url("../assets/fonts/Sharp_Grotesk/SharpGroteskSmBold25.ttf");
}

@font-face {
  font-family: "SpaceRegular";
  src: url("../assets/fonts/Space_Grotesk/SpaceGrotesk-Regular.ttf");
}

@font-face {
  font-family: "SpaceMedium";
  src: url("../assets/fonts/Space_Grotesk/SpaceGrotesk-Medium.ttf");
}

@font-face {
  font-family: "SpaceSemiBold";
  src: url("../assets/fonts/Space_Grotesk/SpaceGrotesk-SemiBold.ttf");
}

@font-face {
  font-family: "SpaceBold";
  src: url("../assets/fonts/Space_Grotesk/SpaceGrotesk-Bold.ttf");
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
  list-style: none;
  text-decoration: none;
}
:root {
  --alerts-danger: #f44336;
  --alerts-warn: #ff9800;
  --alerts-success: #4caf50;
  --alerts-info: #2196f3;
  --main-gray-black: #3a3a3c;
  --white-main: #ffffff;
  --black-main: #000000;
  --black-tint-1: #d4d4d4;
  --black-tint-2: #808080;
  --black-tint-3: #333333;
  --black-tint-4: #373737;
  --yellow-main: #f7941d;
  --yellow-light: #fff4e6;
  --yellow-tint-1: #fdead2;
  --yellow-tint-2: #fbc98e;
  --yellow-tint-3: #f8a643;
  --yellow-tint-4: #ce7b18;
  --yellow-tint-5: #7b4a0e;
  --yellow-tint-6: #311e06;

  --img-bg: #d7d7d7;

  --pointer: pointer;
  --not-allowed: not-allowed;

  --sgMd: "SharpMedium25";
  --sgSb: "SharpSmBld25";

  --regular-400: "SpaceRegular";
  --medium-500: "SpaceMedium";
  --semiBold-600: "SpaceSemiBold";
  --bold-700: "SpaceBold";

  --max-w: 120rem;
  --px: 1.6rem;
  --mb: 16.7rem;
  --mb-sm: 2rem;

  @media screen and (max-width: 768px) {
    --mb: 8rem;
  }

  @media screen and (max-width: 1024px) {
    --pointer: default;
    --not-allowed: default;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

*::selection {
  background-color: #f7941d;
  color: #ffffff;
}

*::-webkit-scrollbar {
  display: none;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  background-color: #ffffff;
}

@media screen and (min-width: 2560px) {
  html {
    font-size: 122.5%;
  }
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 62%;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 60%;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 55%;
  }
}

@media screen and (max-width: 425px) {
  html {
    font-size: 55%;
  }
}

@media screen and (max-width: 375px) {
  html {
    font-size: 50%;
  }
}

@media screen and (max-width: 320px) {
  html {
    font-size: 45%;
  }
}

body {
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-family: "SpaceRegular";
}

#root {
  width: 100%;
  height: 100%;
}

input,
textarea,
button,
select {
  font-family: inherit;
}

h1,
h2 {
  font-family: "SharpSmBld25";
}

h3,
h4,
h5,
h6 {
  font-family: "SharpMedium25";
}

h1 {
  font-size: clamp(4rem, 6vw, 7.2rem);
  line-height: clamp(4.5rem, 6vw, 7.7rem);
}

h2 {
  font-size: clamp(2.4rem, 6vw, 3.2rem);
  line-height: clamp(2.5rem, 6vw, 3.8rem);
}

h3 {
  font-size: clamp(1.8rem, 6vw, 2.4rem);
  line-height: clamp(1.9rem, 6vw, 2.6rem);
}

h4 {
  font-size: clamp(1.4rem, 6vw, 1.6rem);
  line-height: clamp(1.4rem, 6vw, 1.7rem);
}

p {
  font-size: 1.6rem;
  font-family: "SpaceRegular";
  line-height: 2.25rem;
}

section {
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
}

button {
  height: 5.6rem;
  padding: 0 2.4rem;
}

.logo {
  height: 4rem;
  max-width: 7.8rem;
  cursor: pointer;
}

.logo img {
  width: 100%;
  height: 100%;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
  user-select: none;
}

.content_header {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  max-width: 38.4rem;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .content_header {
    max-width: 100%;
  }
}

.content_header-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  margin-bottom: 6.2rem;
}

.content_header-2 p {
  max-width: 38.4rem;
  width: 100%;
  text-align: center;
}

.justify {
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .justify {
    text-align: left;
  }
  .categories-list {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}

.star {
  display: block;
}

@media screen and (max-width: 900px) {
  .star {
    display: none;
  }
}

.privacy-policy {
  padding: 10%;
}

.privacy-policy h2 {
  margin-top: 40px;
}
