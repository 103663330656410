.icon {
  width: 40px;
  height: 40px;
  background-color: var(--accent);
  border-radius: 50%;
  margin-right: 10px;
}
.icon img {
  width: 20px;
  height: 20px;
  /* filter: invert(100%); */
}
/* .category.active .icon img,
.category:hover .icon img {
  filter: invert(100%) !important;
}
.light .icon img {
  filter: invert(0%);
} */
.category {
  width: 100%;
  cursor: pointer;
  padding: 10px 0px;
  margin-bottom: 10px;
  transition: 0.3s all ease-in-out;
  border: none;
  outline: none;
  background: none;
  border-bottom: 1px solid var(--accent);
}

.categories {
  width: 200px;
}
.category span {
  font-size: 1.1em;
}
.category.active span,
.category:hover span {
  color: #f7941d;
  font-weight: 600;
}
.category.active,
.category:hover {
  border-color: #f7941d;
}
.category.active .icon,
.category:hover .icon {
  background-color: #f7941d;
}
.list,
.items {
  flex: 1;
}
.items {
  padding-left: 20px;
}
.list {
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}
.card {
  width: calc(33.33% - 10px);
  height: 250px;
  cursor: pointer;
}
.card .img-card {
  width: 100%;
  height: 150px;
  background-color: #eaeaea;
  border-radius: 10px;
  margin-bottom: 5px;
  overflow: hidden;
}
.card .img-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s all ease-in-out;
}
.card {
  color: #311e06;
}
.card .details h4 {
  font-size: 1.2em;
}
.card:hover .img-card img {
  scale: 1.1;
}
.card .details span {
  font-size: 1em;
}
.price {
  display: block;
  color: white;
  margin-left: auto;
  background-color: #f7941d;
  width: fit-content;
  padding: 3px 5px;
  font-size: 1.2em;
  border-radius: 4px;
  font-weight: 700;
}
.p-image {
  width: 100%;
  height: 300px;
  background-color: #151515;
  position: relative;
  background-repeat: no-repeat;
}
.p-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.p-image .content {
  width: 100%;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translateY(-305px);
}

.item-details {
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  background-color: #311e06;
  transform: translateY(-100px);
  z-index: 10;
  background-color: white;
  box-shadow: 0 0 2100px rgba(0, 0, 0, 0.3);
}
.cart {
  padding: 15px;
  width: 50%;
  border: 1px solid rgba(220, 202, 135, 0.1);
  margin-left: auto;
}
.cart h2 {
  color: #f7941d;
  font-size: 2em;
}
table {
  width: 100%;
  border-collapse: collapse;
}
td {
  padding: 10px;
  width: 15%;
  border-bottom: 1px solid rgba(220, 202, 135, 0.1);
}
.counter .btn {
  width: 30px !important;
  height: 20px !important;
}
.counter .count {
  padding: 0px 10px;
  font-weight: 600;
  color: #f7941d;
}
.item-info span {
  display: block;
}
.item-info span:last-child {
  font-size: 0.6em;
}
.cart-table td.item-info {
  width: 70%;
}
.empty-cart {
  font-size: 0.8em;
  font-style: italic;
  text-align: center;
  display: block;
  padding: 50px;
}
.pricing-table {
  font-size: 0.9em;
  color: var(--white);
  margin-top: 30px;
}
.pricing-table td {
  width: 100%;
  padding: 5px;
}
.item-popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
._menu {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
._menu h2 {
  font-size: 1.8rem;
  color: #311e06 !important;
  margin-bottom: -5px;
}
._menu p {
  font-size: 1.2rem;
}
._menu_details {
  flex: 1;
}
.required {
  font-size: 0.8rem !important;
  background-color: #f7941d;
  padding: 2px;
  color: white;
  border-radius: 2px;
}
._menu_details {
  width: 100%;
}
._menu_details .v-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #ccc;
  border-radius: 4px;
  background: none;
  margin-bottom: 15px;
}
._menu_details .v-btn svg {
  transform: scale(0.8);
}
._menu_details .v-btn svg path {
  fill: #ccc;
}
._menu_details .v-btn span {
  color: #ccc;
}
._menu_details .v-btn.selected-v {
  border-color: #311e06;
}
._menu_details .v-btn.selected-v span {
  color: #311e06;
}
._menu_details .v-btn.selected-v svg path {
  fill: #311e06;
}
._menu_details .v-btn.selected-v svg {
  transform: scale(1.2);
  margin-right: 5px;
}
._menu_modal .m-content {
  max-width: 80% !important;
  max-height: 80vh !important;
  height: 90vh !important;
}
._menu_details {
  flex: 1;
}
.img-qnt {
  flex: 1;
}
.img_qnt_cnt img {
  height: 300px;
  border-radius: 8px;
}
.img_qnt_cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media (max-width: 768px) {
  ._menu {
    flex-direction: column;
  }
  ._menu_modal .m-content {
    max-width: 95% !important;
    max-height: 95vh !important;
    height: 95vh !important;
  }
  .categories,
  .items {
    width: 100%;
  }
  .item-preview {
    margin-bottom: 20px;
    width: 100%;
  }
  .item-details .btn {
    width: 100% !important;
  }
  .items {
    padding-left: 0px;
  }
  .card {
    width: 100%;
    height: fit-content;
    margin-bottom: 15px;
  }
  .card .img-card {
    height: 180px;
  }
  .categories {
    height: 100px;
    white-space: nowrap;
    overflow-y: auto;
  }
  .categories .category {
    display: inline-block;
    width: fit-content;
    margin-right: 10px;
    padding: 10px;
    text-align: center;
    max-width: 150px;
  }
  .categories .category .icon {
    margin-left: auto;
    margin-right: auto;
  }
  .cart {
    width: 100%;
  }
  .td-counter {
    min-width: 100px !important;
  }
  td {
    padding: 5px 2px !important;
  }
  .nav-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100vw;
    z-index: 5;
    transition: 0.3s all ease-in-out;
    background-color: transparent;
  }
  .nav-container.opened {
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .nav-container button {
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    background-color: transparent;
    outline: none;
  }
  .nav-container .nav-content {
    width: 75%;
    background-color: var(--bg);
    height: 100%;
  }
}
.light .card .details h4 {
  color: #f7941d;
}

/* start of main */

/* start of menu  */

.f100 {
  font-weight: 100;
}
.f200 {
  font-weight: 200;
}
.f300 {
  font-weight: 300;
}
.f400 {
  font-weight: 400;
}
.f500 {
  font-weight: 500;
}
.f600 {
  font-weight: 600;
}
.f700 {
  font-weight: 700;
}
.f800 {
  font-weight: 800;
}
.f900 {
  font-weight: 900;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-baseline {
  justify-content: baseline;
}
.justify-end {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-start;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-end {
  align-items: flex-end;
}
.align-start {
  align-items: flex-start;
}
.flex-column {
  flex-direction: column;
}
.app {
  width: 100vw;
  min-height: 100vh;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}
.header {
  width: 100vw;
  height: fit-content;
}
.fixed {
  position: fixed;
  top: 0;
  background-color: var(--bg);
  z-index: 5;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
  .mobile {
    display: none;
  }
  .item-preview {
    max-width: 55%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (max-width: 768px) {
  .mobile-column {
    flex-direction: column;
  }
  .mobile-column-reverse {
    flex-direction: column-reverse;
  }
  .desktop {
    display: none;
  }
}

.light {
  --bg: #fff;
  --black: #f6f6f6;
  --accent: #f1f1f1;
  --grey: #aaaaaa;
  --gold: #dcca87;
  --white: #0c0b08;
}
