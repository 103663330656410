header {
  width: 100%;
  height: 10.4rem;
  position: sticky;
  top: 0;
  background-color: #311e06;
  z-index: 10;
}

header section {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid transparent;
  background-color: #311e06;
  position: relative;
  padding: 0 1.6rem;
}

header section nav {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  z-index: 10;
}

header section nav .logo {
  margin-right: 5rem;
}

header section nav .nav_links {
  display: flex;
  justify-content: space-between;
  width: 50.8rem;
}

header section nav .nav_links .nav_links-link {
  font-size: 1.4rem;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  padding: 0.1rem 0.7rem 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
}

header section nav .nav_links .nav_links-link::before {
  content: "";
  top: 100%;
  position: absolute;
  width: 100%;
  height: 10rem;
  border-radius: 4rem;
  background-color: #f7941d;
  z-index: -1;
  transition: top 0.15s ease-in, background-color 0.1s ease-in;
}

header section nav .nav_links .nav_links-link:hover::before {
  background-color: #fdead2;
  top: 0;
}

header section nav .nav_links .activeLink {
  font-family: "SpaceBold";
}

header section nav .nav_links .activeLink::before {
  top: 80%;
  width: 80%;
  background-color: #f7941d;
  transition: none;
}

header section nav .nav_links .activeLink:hover::before {
  top: 80%;
  background-color: #f7941d;
}

header section nav .nav_right {
  display: flex;
  align-items: center;
  gap: 2rem;
}

header section nav .nav_right .nav_btn {
  height: 4rem;
  background-color: transparent;
  color: #ffffff;
  font-size: 1.4rem;
  padding: 0.5rem 2.4rem;
  border-radius: 8px;
  border: 1px solid #ffffff;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: box-shadow 0.15s ease;
}

header section nav .nav_right .nav_btn {
  box-shadow: -4px 4px 0px 0px #ffffff;
  -webkit-box-shadow: -4px 4px 0px 0px #ffffff;
  -moz-box-shadow: -4px 4px 0px 0px #ffffff;
}

header section nav .nav_right .nav_hamburger {
  display: none;
  cursor: pointer;
}

header section .mobile_nav {
  display: none;
  flex-direction: column;
  gap: 4rem;
  padding: 10.6rem 1.6rem 4rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  transition: top 0.15s ease-in-out;
  z-index: -1;
  pointer-events: all;
}

header section .mobile_nav .nav_links-mobile {
  display: flex;
  flex-direction: column;
}

header section .mobile_nav .nav_links-mobile .nav_links-mobile_link {
  margin-bottom: 3rem;
  font-size: 3rem;
  font-family: "SpaceBold";
  line-height: 3.62rem;
  color: #333333;
  cursor: pointer;
}

header section .mobile_nav .nav_links-mobile .nav_links-mobile_link:last-child {
  margin-bottom: 0;
}

header section .mobile_nav hr {
  width: 16.3rem;
  height: 1px;
  background-color: #cecece;
}

header section .mobile_nav .mobile_nav-btn {
  height: 40px;
  width: max-content;
  background-color: transparent;
  color: #000000;
  font-size: 1.4rem;
  padding: 0.5rem 2.4rem;
  border-radius: 8px;
  border: 1px solid #000000;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: box-shadow 0.15s ease;
}

header section .mobile_nav .mobile_nav-btn:hover {
  box-shadow: -4px 4px 0px 0px #000000;
  -webkit-box-shadow: -4px 4px 0px 0px #000000;
  -moz-box-shadow: -4px 4px 0px 0px #000000;
}

header section .mobile_nav-active {
  top: -150%;
  pointer-events: none;
}

@media screen and (max-width: 886px) {
  header section nav .nav_links {
    display: none;
  }

  header section nav .nav_right .nav_hamburger {
    display: block;
  }

  header section .mobile_nav {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  header section nav .nav_right .nav_btn {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  header {
    height: 8rem;
  }
}

.activeHeader {
  background-color: #ffffff;
}

.activeHeader section {
  background-color: #ffffff;
}

.activeHeader section .nav_links .nav_links-link {
  color: #000000;
}

.activeHeader section .nav_right .nav_btn {
  color: #000000;
  border-color: #000000;
}

.activeHeader section .nav_right .nav_btn {
  box-shadow: -4px 4px 0px 0px #000000;
  -webkit-box-shadow: -4px 4px 0px 0px #000000;
  -moz-box-shadow: -4px 4px 0px 0px #000000;
}
.input-cnt {
  flex: 1;
  margin: 0 10px;
}
.input-cnt .input {
  height: 40px !important;
}
