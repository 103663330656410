.menu-category-item {
  flex: 1;
  aspect-ratio: 0.8;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  min-height: 300px;
  display: flex;
  flex-direction: column;
}
.new-ui-card {
  box-shadow: 5px 5px 34px 0px #00000014;
  background: #ffffff;
  border: 1px solid #0000001a;
  border-radius: 12px;
  padding: 10px;
}
.menu-category-item .img {
  flex: 1;
  width: 100%;
  max-height: calc(100% - 70px);
}
.menu-category-item img {
  width: 100%;
  height: 100%;
}
.menu-category-item .details {
  padding: 15px;
}
.delivery-duration {
  font-family: "SpaceMedium";
  font-size: 1.1em;
  color: #f7941d;
}
.delivery-duration span {
  color: #7b4a0e;
  font-family: "SpaceBold";
}
.menu-categories-list .category-name {
  font-size: 1.8rem;
  font-family: "SpaceBold";
  display: block;
  color: #03081f;
}
.menu-categories-list {
  justify-content: space-between;
  margin: 30px 0px;
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
  width: 100%;
}
.new-heading {
  color: #333333;
  font-size: 4rem;
  margin: 30px 0px;
}
.new-ui-menu {
  box-shadow: 3.65px 3.65px 24.81px 0px rgba(0, 0, 0, 0.08);
  padding: 10px;
  border: 0.73px solid rgba(0, 0, 0, 0.1);
  border-radius: 9px;
  display: flex;
  position: relative;
}
.new-ui-menu div {
  width: 50%;
  padding: 5px;
}
.new-ui-menu div.img,
.new-ui-menu div.img img {
  border-radius: 9px;
  background-color: rgba(0, 0, 0, 0.08);
  padding: 0px;
}

.add-item {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1;
  width: 70px !important;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px 0px 0px 0px;
  cursor: pointer;
}
.filter-splitter {
  display: flex;
  gap: 20px;
}
.filter-categories {
  min-width: 210px;
}
.filter-title {
  color: #f7941d;
  font-size: 1.7rem;
  font-family: "SpaceBold";
  display: block;
  margin-bottom: 10px;
}
.filters button {
  width: 100%;
  text-align: left;
  height: 50px !important;
  margin-bottom: 5px;
  font-size: 1.6rem;
  border-radius: 4px;
  color: #000;
  font-family: "SpaceRegular";
  background: none;
  transition: 0.2s all ease-in-out;
  border-bottom: 1px solid #0000001a;
}
.filters button:hover,
.filters button.active {
  background-color: #f7941d;
  color: #fff;
  border-bottom-width: 0px;
}
.search {
  gap: 10px;
  flex: 1;
  align-items: center;
}
.search .input {
  flex: 1;
  border: 1.5px solid rgba(0, 0, 0, 1);
  padding: 0px;
  height: 50px;
  overflow: hidden;
}
.search .input .s-icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input input {
  flex: 1;
  padding: 10px;
}
.search button {
  border: 1.5px solid rgba(0, 0, 0, 1);
  color: #000;
  background-color: #f7941d;
  border-radius: 8px;
}
.new-ui-menu {
  width: calc(50% - 10px);
  min-height: 245px;
}
.in-menu-list {
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
}
.new-ui-menu .details {
  padding: 20px;
}
.menu-price {
  margin-top: auto;
}

.search-title {
  width: 100%;
  align-items: center;
  gap: 100px;
}
.cart-link {
  position: relative;
}
.cart-link span {
  position: absolute;
  top: -10px;
  right: -10px;
  display: block;
  padding: 1px 8px;
  background-color: #f7941d;
  border-radius: 8px;
  color: #000;
  font-family: "SpaceSemiBold";
}
.cart-display {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.cart-items {
  flex: 1;
}
.cart-item {
  margin-bottom: 20px;
}
.order-text {
  font-size: 3rem;
  color: #333333;
}
.add-btn {
  width: 150px;
  background: #f7941d;
  font-family: "SpaceSemiBold";
  height: 45px;
  color: #fff;
  border-radius: 8px;
  padding: unset;
}
.preview {
  width: 35%;
  max-width: 450px;
  overflow: hidden;
  border-radius: 12px;
  background-color: #f9f9f9;
  border: 1px solid #0000001a;
}
.preview-2 {
  width: 45%;
  max-width: 550px;
}
.p-head {
  background: #311e06;
  color: #fff;
  padding: 20px;
  font-size: 1.8rem;
  font-family: "SpaceBold";
  border: 1px solid #311e06;
}
.preview table {
  margin: 10px 0px;
}
.preview table td {
  padding: 10px 20px !important;
  border: none !important;
  font-size: 1.2rem;
  color: #000;
}
.preview table td:nth-child(2) {
  text-align: right;
  font-family: "SpaceMedium";
}
.total {
  padding: 20px;
  margin: 10px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.total div {
  background-color: #f7941d;
  padding: 10px 20px;
  border-radius: 8px;
}
.total div span {
  color: #fff;
  display: block;
  font-size: 1.4rem;
  font-family: "SpaceSemiBold";
}
.total div span:last-child {
  font-size: 2rem;
}
.delivery-mode {
  padding: 20px;
  margin-bottom: 10px;
}
.delivery-mode button {
  width: 48%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;
  flex-direction: column;
  border: 1px solid #0000001a;
  border-radius: 10px;
}
.delivery-mode button span {
  color: #000;
  font-family: "SpaceSemiBold";
  margin-top: 10px;
}
.delivery-mode button svg path {
  fill: #000;
}
.delivery-mode button.selected svg path {
  fill: #028643;
}
.delivery-mode button.selected {
  border-color: #028643;
  background-color: #0286431a;
}
.delivery-info {
  padding: 20px;
}
.delivery-info input,
.selected-address {
  width: 100%;
  padding: 15px 10px;
  border: 2px solid #7b4a0e;
  /* height: 48px; */
  border-radius: 8px;
  background: none;
  margin: 5px 0px;
  color: #80808080;
  font-family: "SpaceSemiBold";
}
.selected-address {
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selected-address span {
  display: block;
  color: #80808080;
  font-family: "SpaceSemiBold";
  font-size: 1.2rem;
  margin-left: 6px;
}

.delivery-info input:focus,
.selected-address.filled {
  color: #f7941d;
  border-color: #f7941d;
  background-color: #fff;
}
.selected-address.filled svg path {
  fill: #f7941d;
}
.checkout-btn {
  padding: 20px;
}
.checkout-btn .btn {
  width: 100%;
  height: 50px !important;
}
.pay-btn {
  background: #028643 !important;
}
.checkout-btn .btn svg,
.pay-btn svg {
  width: 18px;
  margin-right: 10px;
}
.d-title {
  font-size: 1.2rem;
  color: #000;
  font-family: "SpaceSemiBold";
  display: block;
  margin-bottom: 10px;
}
.menu-img,
.menu-img img {
  width: 100px;
  height: 100px;
  border-radius: 12px;
  background-color: #fcfcfc;
}
.menu-details {
  padding: 0px 10px;
}
.menu-details span {
  display: block;
}
.menu-name {
  color: #00000080;
  font-size: 1.4rem;
  font-family: "SpaceSemiBold";
}
.menu-details p {
  font-size: 1.2rem;
  color: #000;
}
.price-counter {
  margin-left: auto;
}
.cart-menu-price {
  font-size: 2rem;
  color: #000;
  font-family: "SpaceBold";
  display: block;
  margin-bottom: 10px;
  text-align: right;
}
.counter button,
.count-btn {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: unset;
  margin: unset;
  cursor: pointer;
}
.counter span {
  display: block;
  font-size: 1.2rem;
  color: #f7941d;
  font-family: "SpaceSemiBold";
  padding: 0px 15px;
}
.gradient {
  background: radial-gradient(
    circle at bottom left,
    #5b2501,
    #311e06,
    #311e06,
    #5b2501
  );
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  padding: 20px;
  margin-bottom: 80px;
  flex-direction: column;
}
.gradient h2 {
  color: white;
  text-align: center;
  font-size: 3.5rem;
}
.filters-mobile {
  display: none;
}
.gradient .input {
  background-color: #fff;
  padding: 0px;
  height: 50px;
  border-radius: 4px;
  max-width: 600px;
}
.gradient .s-icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.floating {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7941d;
  border-radius: 50%;
  color: white;
  font-family: "SpaceBold";
  font-size: 1.2rem;
}
.delivery-address {
  padding: 20px;
  border-top: 1px solid #0000001a;
}
.delivery-address span {
  display: block;
  color: #03081f;
  font-size: 1.2rem;
  font-family: "SpaceMedium";
}
.delivery-address p {
  color: #212121;
  font-size: 1.4rem;
  font-family: "SpaceBold";
}
.p-head span {
  font-size: 1.3rem;
  color: #f7941d;
  font-family: "SpaceRegular";
}
.rider-info {
  border-top: 1px solid #0000001a;
  padding: 20px;
}
.rider-details {
  flex: 1;
  padding: 0px 10px;
}
.rider-img,
.rider-img img {
  width: 70px;
  border-radius: 10px;
}
.phone {
  width: 50px;
  height: 50px;
  background: #4caf50;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.rider-details span {
  display: block;
  font-family: "SpaceMedium";
  font-size: 1.1rem;
}
.rider-name {
  font-size: 1.8rem !important;
  font-family: "SpaceSemiBold";
  color: #212121;
}
.rider-details span:first-child {
  color: #03081f;
}
.rider-details span:last-child {
  color: #028643;
}
.app-load {
  width: 100vw;
  height: 100vh;
}
.incart-count {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #028643;
  border-radius: 50%;
  border: 2px solid #fff;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "SpaceSemiBold";
}
.order-timeline {
  padding: 20px;
  transition: 0.3s all ease-in-out;
  padding-left: 10px;
  /* padding-bottom: 15px; */
}
.timeline-icon {
  width: 60px;
  margin-right: 15px;
  position: relative;
}
.timeline-icon div {
  border-radius: 50%;
  aspect-ratio: 1;
  z-index: 2;
}
.timeline-icon div {
  width: 35px;
  background-color: #fbc98e;
}
.timeline-completed .timeline-icon div {
  width: 55px;
  background-color: #4caf50;
}
.order-timeline .timeline-desc {
  opacity: 0.3;
}
.order-timeline.timeline-completed .timeline-desc {
  opacity: 1;
}
.order-timeline span {
  display: block;
}
.timeline-icon::before {
  content: "";
  width: 2px;
  height: 210%;
  position: absolute;
  top: 90%;
  left: calc(50% - 1px);
  background-color: #fbc98e;
}
.city-flag {
  width: 40px !important;
  height: 30px !important;
  background: none;
  border: none;
  background-color: #5b2501;
  display: flex;
  justify-content: center;
  align-items: center;
}
.city-flag img {
  width: 40px !important;
  height: 30px;
  object-fit: contain;
}
.country-options {
  width: 200px;
  position: absolute;
  top: 70px;
  right: 0px;
}
.country-options button {
  width: 100%;
  background-color: #5b2501;
}
.country-options button:hover,
.country-options button.active-country {
  background-color: #421b01;
}
.country-options button span {
  display: block;
  margin-left: 10px;
  color: white;
}
.timeline-completed .timeline-icon::before {
  background-color: #4caf50;
  height: 170%;
}
.order-timeline.last .timeline-icon::before {
  display: none;
}
.order-timeline span:nth-child(1) {
  font-size: 1.8rem;
  font-family: "SpaceSemiBold";
  color: #000;
  margin-bottom: 6px;
}
.order-timeline span:nth-child(2) {
  font-size: 1.3rem;
}
.order-timeline span:nth-child(3) {
  font-size: 1.5rem;
  color: #f7941d;
}
.status {
  padding: 6px 10px;
  width: fit-content;
  border-radius: 3px;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  font-size: 1rem;
}

.delivered {
  background-color: var(--alerts-success);
}
.rejected {
  background-color: var(--alerts-danger);
}
.pending {
  background-color: var(--alerts-warn);
}
.processing {
  background-color: var(--alerts-info);
}
.dispatched {
  background-color: var(--yellow-tint-5);
}
.icart-display {
  background-color: #421b01;
  border-radius: 10px;
  overflow: hidden;
  max-width: 300px;
}
.icart-display a {
  display: block;
  margin-bottom: 10px;
  color: var(--yellow-main);
  text-decoration: underline;
}
.icart-display {
  color: var(--yellow-main);
  height: fit-content;
}
.icart-display .icart-desc {
  padding: 10px;
}
.icart-display h3 {
  font-size: 1.5rem;
}
.icarts-lists {
  width: 100vw;
  height: 100vh;
  border-color: red;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  overflow-y: auto;
}
.icart-display .icart-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 8px 8px 0px 0px;
}
.menu-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}
.menu-images img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}
@media (min-width: 760px) {
  .menu-categories-list.length-1 .menu-category-item {
    max-width: 33%;
  }
  .menu-categories-list.length-2 .menu-category-item {
    max-width: 47%;
  }
}
@media (max-width: 760px) {
  .cart-items {
    width: 100%;
  }
  .menu-category-item {
    min-width: 30%;
    min-height: 230px;
  }
  .new-heading {
    font-size: 2rem;
    margin: unset;
  }
  .new-ui-menu {
    width: 100%;
    min-height: unset;
  }
  .filter-splitter {
    flex-direction: column;
  }
  .new-ui-menu .details {
    padding: 10px;
  }
  .search-title {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
  .search-title h3 {
    margin-bottom: 20px;
  }
  .search {
    width: 100%;
  }
  .cart-display {
    flex-direction: column;
  }
  .preview {
    max-width: 100%;
    width: 100%;
  }
  .order-text {
    font-size: 2.2rem;
  }
  .counter button,
  .count-btn {
    width: 25px !important;
    height: 25px !important;
  }
  .counter span {
    padding: 0px 10px;
  }
  .cart-items {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #0000001a;
  }
  .gradient {
    height: 200px;
    margin-bottom: 40px;
  }
  .gradient h2 {
    font-size: 2.5rem;
  }
  .filters-mobile {
    display: flex;
    overflow-x: auto;
  }
  .filters-mobile button {
    width: fit-content !important;
    border-bottom-width: 0px;
    margin-right: 10px;
  }
  .filter-categories {
    display: none;
  }
}
