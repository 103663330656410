footer {
  display: flex;
  flex-direction: column;
}

footer .newsletter_form {
  text-align: center;
  padding: 6.3rem 1.6rem;
  background: #311e06;
  background: radial-gradient(
    circle at bottom left,
    #5b2501,
    #311e06,
    #311e06,
    #5b2501
  );
}

footer .newsletter_form section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

footer .newsletter_form section .newsletter_form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  color: #ffffff;
}

footer .newsletter_form section .newsletter_form-control {
  display: flex;
  gap: 2.4rem;
  max-width: 99.6rem;
  width: 100%;
}

footer .newsletter_form section .newsletter_form-control input,
.input {
  width: 100%;
  height: 5.6rem;
  border-radius: 8px;
  background-color: transparent;
  color: #ffffff;
  font-size: 14px;
  border: 1px solid #ffffff;
  padding: 1.9rem 2.4rem;
}

footer .newsletter_form section .newsletter_form-control input::placeholder,
.input::placeholder {
  color: #d4d4d4;
}

footer .newsletter_form section .newsletter_form-control button {
  width: 18rem;
  height: 5.6rem;
  border-radius: 8px;
  border: 1px solid #000000;
  background-color: #f7941d;
  font-size: 1.4rem;
  font-family: "SpaceMedium";
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  footer .newsletter_form section .newsletter_form-control {
    flex-direction: column;
  }

  footer .newsletter_form section .newsletter_form-control button {
    width: 100% !important;
  }
}

footer .footer_bottom {
  background-color: #f7941d;
  padding: 4.7rem 1.6rem;
}

footer .footer_bottom p {
  color: #333333;
  font-weight: 600;
  font-size: 1.6rem !important;
  font-family: "SpaceSemiBold";
}

footer .footer_bottom section {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

footer .footer_bottom section .top_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5rem;
}

footer .footer_bottom section .top_section .footer_section-left,
footer .footer_bottom section .top_section .footer_section-right {
  flex: 1 1 35rem;
}

footer .footer_bottom section .top_section .footer_section-left {
  display: flex;
  flex-direction: column;
  gap: 3.3rem;
}

footer .footer_bottom section .top_section .footer_section-left p {
  max-width: 28.2rem;
  width: 100%;
}

@media screen and (max-width: 689px) {
  footer .footer_bottom section .top_section .footer_section-left p {
    max-width: 100%;
  }
}

footer .footer_bottom section .top_section .footer_section-right {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2.4rem;
}

footer
  .footer_bottom
  section
  .top_section
  .footer_section-right
  .right_section-content {
  flex: 1 1 15rem;
  max-width: 18rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

footer
  .footer_bottom
  section
  .top_section
  .footer_section-right
  .right_section-content
  p {
  font-size: 1.4rem !important;
  font-family: "SpaceSemiBold" !important;
  font-weight: 600;
}

footer
  .footer_bottom
  section
  .top_section
  .footer_section-right
  .right_section-content
  ul {
  display: flex;
  flex-direction: column;
}

footer
  .footer_bottom
  section
  .top_section
  .footer_section-right
  .right_section-content
  ul
  .link {
  height: 4rem;
  width: max-content;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  cursor: pointer;
  border-radius: 8px;
}

footer
  .footer_bottom
  section
  .top_section
  .footer_section-right
  .right_section-content
  ul
  .link
  span {
  margin-bottom: -0.3rem;
}

footer
  .footer_bottom
  section
  .top_section
  .footer_section-right
  .right_section-content
  ul
  .link:hover {
  background-color: #fdead2;
}

footer
  .footer_bottom
  section
  .top_section
  .footer_section-right
  .right_section-content
  ul
  .link
  p {
  font-size: 1.6rem !important;
  font-family: "SpaceRegular" !important;
  font-weight: 400;
}

footer .footer_bottom section .bottom_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
}

footer .footer_bottom section .bottom_section div {
  display: flex;
  align-items: center;
  gap: 3.2rem;
}

footer .footer_bottom section .hr {
  width: 100%;
  border-bottom: 1px solid #333333;
}
